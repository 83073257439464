var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { dataimpService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var DataInfoExcelService = /** @class */ (function (_super) {
    __extends(DataInfoExcelService, _super);
    function DataInfoExcelService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 案件导入数据
     */
    DataInfoExcelService.prototype.getDataInfoExcelList = function (data, page, loading) {
        return this.netService.send({
            server: dataimpService.dataInfoExcelController.getDataInfoExcelList,
            data: {
                custName: data.custName,
                caseAmt: data.caseAmt,
                overDueDays: [data.overDueDays.min, data.overDueDays.max],
                overdueAmount: [data.overdueAmount.min, data.overdueAmount.max],
                batchNumber: data.batchNumber,
                prinName: data.prinName,
                cupoName: data.cupoName,
                cupoStatus: data.cupoStatus,
                cupoGettime: data.cupoGettime,
                companyCode: data.companyCode,
                personalName: data.personalName,
                mobileNo: data.mobileNo,
                color: data.color,
                dayRange: data.dayRange,
                caseMoney: data.caseMoney,
                caseMark: data.caseMark
            },
            page: page,
            loading: loading
        });
    };
    /**
     * 案件导入确定
     */
    DataInfoExcelService.prototype.importExcelData = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataInfoExcelController.importExcelData,
            data: data,
            loading: loading
        });
    };
    /**
     * 获取批次号列表
     */
    DataInfoExcelService.prototype.queryBatchNumGroup = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataInfoExcelController.queryBatchNumGroup,
            data: data,
            loading: loading
        });
    };
    DataInfoExcelService.prototype.checkCasesFile = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataInfoExcelController.checkCasesFile,
            data: data,
            loading: loading
        });
    };
    DataInfoExcelService.prototype.casesConfirmByBatchNum = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataInfoExcelController.casesConfirmByBatchNum,
            data: data,
            loading: loading
        });
    };
    DataInfoExcelService.prototype.deleteCasesByBatchNum = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataInfoExcelController.deleteCasesByBatchNum,
            data: data,
            loading: loading
        });
    };
    DataInfoExcelService.prototype.exportError = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataInfoExcelController.exportError,
            data: data,
            loading: loading
        });
    };
    DataInfoExcelService.prototype.findUpload = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataInfoExcelController.findUpload,
            data: data,
            loading: loading
        });
    };
    DataInfoExcelService.prototype.getDataInfoExcelDetails = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataInfoExcelController.getDataInfoExcelDetails,
            data: data,
            loading: loading
        });
    };
    DataInfoExcelService.prototype.loadTemplate = function (loading) {
        return this.netService.send({
            server: dataimpService.dataInfoExcelController.loadTemplate,
            loading: loading
        });
    };
    DataInfoExcelService.prototype.uploadCaseFileSingle = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataInfoExcelController.uploadCaseFileSingle,
            data: data,
            loading: loading
        });
    };
    DataInfoExcelService.prototype.findError = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataInfoExcelController.findError,
            data: data,
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], DataInfoExcelService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], DataInfoExcelService.prototype, "getDataInfoExcelList", null);
    __decorate([
        Debounce()
    ], DataInfoExcelService.prototype, "importExcelData", null);
    __decorate([
        Debounce()
    ], DataInfoExcelService.prototype, "queryBatchNumGroup", null);
    __decorate([
        Debounce()
    ], DataInfoExcelService.prototype, "checkCasesFile", null);
    __decorate([
        Debounce()
    ], DataInfoExcelService.prototype, "casesConfirmByBatchNum", null);
    __decorate([
        Debounce()
    ], DataInfoExcelService.prototype, "deleteCasesByBatchNum", null);
    __decorate([
        Debounce()
    ], DataInfoExcelService.prototype, "exportError", null);
    __decorate([
        Debounce()
    ], DataInfoExcelService.prototype, "findUpload", null);
    __decorate([
        Debounce()
    ], DataInfoExcelService.prototype, "getDataInfoExcelDetails", null);
    __decorate([
        Debounce()
    ], DataInfoExcelService.prototype, "loadTemplate", null);
    __decorate([
        Debounce()
    ], DataInfoExcelService.prototype, "uploadCaseFileSingle", null);
    __decorate([
        Debounce()
    ], DataInfoExcelService.prototype, "findError", null);
    return DataInfoExcelService;
}(Service));
export { DataInfoExcelService };
